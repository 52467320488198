import api from './services/api'

// Função para enviar a inscrição para o servidor
const sendSubscriptionToServer = async (subscription) => {
  try {
    const stringFormat = JSON.stringify(subscription)
    const objectsFormat = JSON.parse(stringFormat)
    objectsFormat.contentEncoding = 'aesgcm'
    const response = await api
      .post('/api/save-subscription', JSON.stringify(objectsFormat), {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    if (!response.ok) {
      throw new Error('Falha ao salvar a inscrição no servidor')
    }
  } catch (error) {
  }
}

function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
  const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/')

  const rawData = window.atob(base64)
  const outputArray = new Uint8Array(rawData.length)

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}

function subscribeUser() {
  navigator.serviceWorker.ready
    .then((registration) => {
      const subscribeOptions = {
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(
          process.env.REACT_APP_VAPID_PUBLIC_KEY
        )
      }

      return registration.pushManager.subscribe(subscribeOptions)
    })
    .then((pushSubscription) => {
      sendSubscriptionToServer(pushSubscription)
    })
}

// Função para solicitar permissão de notificação
export const requestNotificationPermission = async () => {
  if ('Notification' in window && Notification.permission !== 'denied') {
    const permission = await Notification.requestPermission()
    if (permission === 'granted') {
      subscribeUser() // Após permissão, obter inscrição
    } else {
    }
  }
}

export const unsubscribeUser = async () => {
  try {
    const registration = await navigator.serviceWorker.ready
    const subscription = await registration.pushManager.getSubscription()

    if (subscription) {
      // Enviar a desinscrição para o servidor
      await api
        .post('api/save-subscription', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ endpoint: subscription.endpoint })
        })
        .then(({ ...response }) => response)
        .catch(({ ...response }) => response)

      // Desinscrever o usuário
      await subscription.unsubscribe()
    }
  } catch (error) {
    console.error('Erro ao desinscrever o usuário:', error)
  }
}
