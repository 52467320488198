// ** React Imports
import { Suspense, lazy, useEffect } from 'react'
import ReactDOM from 'react-dom'

// ** Redux Imports
import { Provider } from 'react-redux'
import { store } from './redux/storeConfig/store'

// ** Intl, CASL & ThemeColors Context
import ability from './configs/acl/ability'
import { ToastContainer } from 'react-toastify'
import { AbilityContext } from './utility/context/Can'
import { ThemeContext } from './utility/context/ThemeColors'
import { IntlProviderWrapper } from './utility/context/Internationalization'

// ** Spinner (Splash Screen)
import Spinner from './@core/components/spinner/Fallback-spinner'

// ** Ripple Button
import './@core/components/ripple-button'

// ** PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import 'prismjs/components/prism-jsx.min'

// ** React Perfect Scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css'

// ** React Toastify
import '@styles/react/libs/toastify/toastify.scss'

// ** Core styles
import './@core/assets/fonts/feather/iconfont.css'
import './@core/scss/core.scss'
import './assets/scss/style.scss'
import './assets/scss/Calendar.scss'
import './assets/scss/DatePicker.scss'

// ** Service Worker
// import * as serviceWorker from './serviceWorker'

import { HomeProvider } from './utility/hooks/useHome'
import { RolloverNotificationProvider } from './utility/hooks/useRolloverNotification'
import { ModalHunterProvider } from './utility/hooks/useModalHunter'
import { DiscadorProvider } from './utility/hooks/useDiscador'
import {
  requestNotificationPermission,
  unsubscribeUser
} from './pushNotifications'

// ** Lazy load app
const LazyApp = lazy(() => import('./App'))
const MainApp = () => {
  useEffect(() => {
    if ('Notification' in window && navigator.serviceWorker) {
      requestNotificationPermission()
    } else {
      // console.log('Este navegador não suporta notificações push.')
    }

    const handleBeforeUnload = () => {
      unsubscribeUser()
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [])

  // Renderização do app principal
  return (
    <Suspense fallback={<Spinner />}>
      <AbilityContext.Provider value={ability}>
        <ThemeContext>
          <RolloverNotificationProvider>
            <ModalHunterProvider>
              <IntlProviderWrapper>
                <HomeProvider>
                  <DiscadorProvider>
                    <LazyApp />
                    <ToastContainer position="top-center" newestOnTop />
                  </DiscadorProvider>
                </HomeProvider>
              </IntlProviderWrapper>
            </ModalHunterProvider>
          </RolloverNotificationProvider>
        </ThemeContext>
      </AbilityContext.Provider>
    </Suspense>
  )
}

// Registrando o serviço
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register('/service-worker.js')
      // .then((registration) => {
      //   console.log('Service Worker registrado com sucesso: ', registration)
      // })
      // .catch((error) => {
      //   console.log('Falha ao registrar o Service Worker: ', error)
      // })
  })
}

// Renderizando o componente
ReactDOM.render(
  <Provider store={store}>
    <MainApp />
  </Provider>,
  document.getElementById('root')
)